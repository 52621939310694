import React from 'react'
import { HashLink as Link } from 'react-router-hash-link';


//Page politique de confidentialité
const PrivacyPolicy = (props)=>{

    return (
        <main id="privacy_policy" className='footerLink'>
            <h1 className='titre_h1'>Book2life Politique de confidentialité</h1>
            <p className='update_poilicy'>Date de la dernière mise à jour: 08/09/2022</p>

         
            <p>Sit earum porro aut asperiores dicta est consectetur quos? Ex officiis consequatur et accusantium dignissimos aut voluptatibus quod et esse ipsa. At internos accusantium non numquam similique et consequatur cumque ab aliquam veritatis. Et magni excepturi et odit Quis est recusandae omnis non soluta error aut ipsa consequuntur.</p>

            <ol className='ancre'>
                <li><Link to="#generalites">Généralités</Link> </li>
                <li><Link to="#collecteDonnees">Pourquoi et comment collectons-nous et utilisons-nous vos données ?</Link></li>
                <li><Link to="#beneficiairesDonnees"> Bénéficiaires des données à caractère personnel</Link></li>
                <li><Link to="/utilisateurPro">Ce que vous devez savoir lorsque vous interagissez avec un Utilisateur Pro </Link></li>
                <li><Link to="#cookies">Utilisation de cookies</Link></li>
                <li><Link to="#droitsAmendement"> Droit d’amendement</Link></li>
                <li><Link to="#droitsStatutaire">Vos droits statutaires concernant vos données</Link></li>
                <li><Link to="#coordonnées">Nos coordonnées</Link></li>
            </ol>

            <section className='container_policy'>
                <h3 id="generalites" className='bold titre_h3'>Généralités</h3>

                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Minima, quas magnam esse alias omnis dicta, veritatis voluptatibus ducimus maiores, quod laborum dolorum sequi quisquam tempore accusamus numquam dolorem temporibus repellat cupiditate blanditiis! In nulla voluptatum libero vel tempora mollitia est? Aliquam natus cumque explicabo inventore. Nulla magnam dolor dolores veritatis maxime sunt officiis dolorem neque at iusto, a dignissimos iure natus tenetur deleniti. Sequi error distinctio corrupti vero repellendus in id adipisci cum exercitationem, neque est architecto illum necessitatibus corporis laborum aspernatur laboriosam dolorem vitae quas. Praesentium voluptatem numquam quasi, distinctio iste rerum quaerat cupiditate saepe, quidem error quod cum quas reprehenderit similique ex laborum illum recusandae eos delectus nobis eveniet molestiae et ea porro! Cupiditate in corrupti adipisci. Labore voluptate ab at, amet tempore minus ut aliquid sed neque error excepturi consequatur assumenda sequi recusandae molestias exercitationem illo temporibus, nam, aliquam delectus voluptatibus et! Nemo praesentium harum hic excepturi eaque nihil nisi error voluptatibus. Incidunt voluptatem aliquam at reiciendis consequuntur consectetur esse, sunt delectus iste ex, magnam pariatur voluptatibus recusandae perferendis expedita explicabo, provident placeat eum ipsa iusto inventore possimus enim totam animi? Magnam numquam sequi officia tempora maxime doloribus minus obcaecati adipisci! Omnis assumenda numquam possimus delectus dignissimos?</p>
                    <p>Hic aliquid voluptatum ab consequatur incidunt aspernatur quisquam illo earum aut. Consequatur nihil illum similique voluptatem, dolores quas amet porro unde quod rem quo? At aliquid unde modi illo repellendus ullam officia dolore doloribus ipsam, voluptatum, eos quis eius. Fugit distinctio sit nulla nobis eius tempora quo hic a quasi laudantium. Blanditiis, cumque cum quaerat debitis enim aspernatur aliquam neque, et architecto non deserunt totam incidunt ab corrupti facere laudantium, maxime expedita dolorem. Exercitationem doloribus nesciunt sit porro pariatur totam neque mollitia architecto enim perspiciatis ea soluta repellat corrupti minima reprehenderit ipsam odit aliquid tenetur, deserunt aut quod, sapiente officia rem. Nulla sapiente neque accusantium a eum magnam, qui odit rem mollitia placeat pariatur maxime officia repudiandae, repellendus error reiciendis suscipit. Doloribus culpa facere qui, iure quidem minima quisquam quasi temporibus id similique accusantium sapiente facilis! Molestias quae ipsum similique nemo harum quasi neque tempore, animi cupiditate. Mollitia consequuntur ea esse eius, sit facilis et quasi aut nihil cum aliquam totam iure eaque eos autem perferendis nemo exercitationem soluta, sunt aspernatur accusamus, dolor blanditiis enim! Labore veniam doloremque officia rerum natus aspernatur aliquid cum repellat ex enim laboriosam at sunt quasi, alias blanditiis nesciunt deserunt! Magnam maxime debitis iure modi.</p>
                    <p>Dicta magnam sint similique, unde dolorem provident minus! Numquam rem sint debitis alias totam vitae exercitationem quidem eius animi cum provident quis, eos pariatur. Maiores omnis accusamus recusandae, a beatae placeat quam nesciunt quae exercitationem molestiae ipsa, suscipit in! Ipsam at harum ipsum saepe repellendus magni doloremque porro necessitatibus nam vero est, commodi provident dolor consectetur ad voluptates iure cumque atque accusamus, eius officiis doloribus exercitationem? Laboriosam nam illum at, aliquid doloremque voluptatum ducimus sint perspiciatis sequi culpa dolor odio cum reiciendis repudiandae adipisci officiis assumenda praesentium nihil tempora fugit similique dicta iure aperiam! Minus sunt, itaque illum laudantium amet eum fugit exercitationem dignissimos perferendis. Sint ex accusamus, libero ullam quaerat nisi adipisci, ab perspiciatis esse amet quos sit non cum commodi quae inventore in. At provident, recusandae unde et eligendi quod quam molestias obcaecati earum minus non ea soluta cum omnis beatae porro eum amet doloribus quia sed. Rerum ea deserunt cupiditate quod quam, suscipit odio saepe consequatur vel blanditiis, dolores inventore exercitationem maiores, animi facere tempore iure est nihil. Nam, dolore mollitia. Beatae, animi tenetur. Ipsam enim nobis molestiae culpa consequatur. Distinctio, adipisci quasi corporis, perferendis expedita illum quas, sequi quaerat odit laborum voluptate obcaecati aspernatur neque ratione?</p>
                    

                <h3 id="collecteDonnees" className='bold titre_h3'>Pourquoi et comment collectons-nous et utilisons-nous vos données ?</h3>

                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolor expedita reiciendis ut laboriosam odio quo vero facere veritatis est cupiditate neque placeat totam nobis cum, quaerat maxime aspernatur ratione et ullam amet quod! Ab dicta similique laudantium obcaecati placeat eius? Voluptatum, veniam distinctio! Ea accusamus molestiae recusandae vitae quos asperiores tempore velit pariatur exercitationem eius nobis iure obcaecati molestias sequi odit corrupti provident dolor consequuntur doloribus, voluptates ex? Deserunt ullam quis, doloribus quas magnam, ipsam dolorem qui officia ea at assumenda. Placeat pariatur est repellat, soluta suscipit impedit maxime ipsum sit voluptatum nihil quibusdam modi, odit quo non, reprehenderit tempore.</p>
                    <p>Eligendi laudantium atque quisquam, reiciendis odit illo repudiandae voluptate ad quis perspiciatis vero minima voluptatem porro eos, ex deserunt est dolorem, facilis sint esse sunt mollitia quos. Nobis odio nihil velit! Nisi consectetur laboriosam nesciunt unde quo quae eum nam veniam aliquam iusto ut voluptate veritatis vel, ducimus ratione amet laborum! Sequi mollitia est deleniti nisi nesciunt. Sit veritatis quos quia architecto dolorem cumque sint provident aliquid dolore rem sequi modi aspernatur obcaecati facilis dignissimos distinctio, laboriosam voluptatum explicabo commodi consequatur qui. Ducimus sed architecto dolore amet ratione dolorem, dolor quas iusto facilis cupiditate quibusdam, adipisci, incidunt eos eaque nesciunt!</p>
                    <p>Asperiores cupiditate pariatur at natus necessitatibus dolorum quo veritatis delectus minima doloribus maiores unde dolorem velit earum, ex, architecto laboriosam consequatur quis aliquid molestias. Reiciendis quibusdam sapiente doloribus cum. Numquam, modi consectetur! Sed perferendis saepe, tempora accusamus mollitia suscipit, necessitatibus distinctio pariatur amet explicabo nihil a molestias excepturi nostrum nam adipisci in incidunt recusandae animi est. Consectetur repellat suscipit, veniam nesciunt velit quae aliquam eaque rem assumenda, numquam sunt doloribus totam. Enim quae ullam saepe soluta nam sunt ab repellat maiores, maxime, eaque perferendis minus sequi provident molestiae harum nihil inventore voluptatem sit debitis dolorum. Repellat minus delectus similique ratione?</p>
 
                <h3 id="beneficiairesDonnees" className='bold titre_h3'> Bénéficiaires des données à caractère personnel</h3>
                    <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Suscipit exercitationem recusandae, placeat cupiditate ut eum et fuga accusantium dignissimos vel quasi sunt molestiae sapiente explicabo itaque ipsa? Eveniet optio iste quia libero cum porro dolorum, iure incidunt aliquid, nisi in tempora temporibus. Vitae illo distinctio ex magnam? Incidunt dignissimos exercitationem officia praesentium quae iure porro eligendi vel reprehenderit, labore asperiores, debitis rem ipsa tempora, facilis omnis? Sed, dignissimos! Expedita, minima.</p>
                    <p>Perspiciatis rerum quaerat cum. Nemo explicabo natus minus quidem eum amet expedita neque, mollitia enim, quis omnis est, quos labore? Odit sapiente amet magni, in velit tempore? Praesentium similique maxime porro perferendis amet. Dolor, cupiditate? Perspiciatis delectus nobis porro iure vel harum obcaecati eveniet reiciendis, dolorem alias labore non doloribus qui explicabo temporibus tempora a natus nostrum sequi nihil? Dolore, perspiciatis vel! Mollitia doloribus ad vitae quae aliquid maxime sequi?</p>
                    <p>Itaque harum repellendus, voluptates autem tempora voluptatibus sed ex perspiciatis obcaecati libero aspernatur sequi ea et temporibus non est facere aliquid odit quisquam laudantium. Reiciendis, ea provident velit iste illum adipisci illo sequi quaerat impedit molestias libero obcaecati accusantium vitae qui? Nesciunt dolores voluptatibus eius, labore architecto inventore, cumque, dolor aliquam eligendi tempore beatae quisquam eos quaerat minus. Totam rem enim nostrum accusamus optio ullam voluptatum cum eum aliquid tenetur!</p>

                <h3 id="utilisateurPro" className='bold titre_h3'>Ce que vous devez savoir lorsque vous interagissez avec un Utilisateur Pro </h3>

                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto itaque soluta sunt ab, sit perferendis illum eveniet explicabo possimus illo eligendi tempora quae nihil praesentium iusto unde nulla velit recusandae dignissimos mollitia? Ratione, harum molestias? Dicta voluptatum fuga vero sint exercitationem tempora odio ea incidunt reiciendis expedita. Fugit explicabo odio a corporis ipsa ducimus veritatis. Cumque, voluptas nisi sapiente facere quaerat architecto quam facilis deserunt tempore ipsam aliquam iure eos ex veniam consequatur fugit sint et! Doloribus alias mollitia ipsum dolorum perferendis repudiandae voluptate obcaecati voluptatibus ipsam veritatis perspiciatis, similique omnis qui a facere! Quis perferendis illum nihil vitae neque sint repudiandae ratione laborum minus quidem, molestias ducimus dolorum accusamus. Provident, nesciunt! Architecto consequuntur accusantium, delectus ratione illo magni alias quis veniam numquam in vero quae at labore, nobis quod dolorem voluptate consequatur. Tempora perspiciatis autem velit! Nobis consectetur atque officia, ipsa perferendis voluptatibus. Amet eligendi beatae hic doloremque perspiciatis?</p>
                    <p>Recusandae numquam consectetur fuga consequuntur, eius blanditiis adipisci assumenda saepe voluptatem excepturi quos? Commodi voluptates blanditiis suscipit facere obcaecati qui a, repellendus veritatis accusamus, ab ipsum placeat consequuntur sed, illo saepe! Omnis quasi ab libero atque cumque repellat ratione hic? Eius enim nesciunt libero alias autem adipisci. Minima iure laboriosam, quae optio facilis porro? Incidunt doloremque animi dolorem! Debitis fuga mollitia et neque quod blanditiis? Sed sit facere vero! Amet veritatis rerum dolore maxime, hic quas voluptates accusantium natus, ex repellendus modi, cum perferendis reprehenderit facilis iste voluptatum esse repellat ab eum aperiam? Incidunt nam laudantium doloribus magni earum aliquam assumenda beatae, illum voluptate est sed dolore nulla fuga itaque modi id dolor. Tempore ipsa, itaque eligendi vitae, laudantium, necessitatibus atque esse iusto aliquam sint autem amet quas qui ullam. Facere totam libero iusto sequi sapiente. Dignissimos dolorum earum repellat necessitatibus eveniet fugit repudiandae porro, dolore veritatis vero doloribus repellendus?</p>
                    <p>Doloremque minima vero, maxime harum non nisi iste, quasi expedita, voluptates magnam est eos placeat labore cumque numquam cupiditate libero saepe aperiam unde modi? Provident voluptatem consequatur rerum vitae laudantium quasi doloribus exercitationem aut quos dolor eaque sit accusamus magnam inventore, possimus quam cum sint velit debitis consectetur obcaecati ex praesentium mollitia? Odio vitae quibusdam aspernatur quidem odit ipsum voluptatum vero error recusandae in. Asperiores, odio. Ipsum aspernatur fugit in perspiciatis explicabo sunt veniam error commodi, eveniet beatae! Ad, repellat sunt! Consectetur itaque molestiae provident et quas repellat, perspiciatis id delectus dolore veniam, odio, asperiores quaerat quidem ad eligendi amet exercitationem rerum odit repudiandae aliquid ratione ea? Consectetur quae iusto illo perferendis, quo autem, ipsa quasi libero dicta aliquid ipsam nostrum soluta, ab eligendi. Itaque, enim vitae temporibus, ipsa harum consectetur dignissimos iusto, dolorem eligendi odio modi aspernatur. Voluptatem harum nam minus nisi quis, dolorum aliquid adipisci? Consequatur, rerum nostrum.</p>

                <h3 id="cookies" className='bold titre_h3'>Utilisation de cookies</h3>

                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab quasi illo nam quo, blanditiis dolor assumenda vero optio sed aperiam corrupti eum iste quas explicabo delectus consectetur! Quis reprehenderit deleniti nemo error nulla nisi minima, odit obcaecati modi voluptates veritatis vitae ex itaque delectus sequi qui exercitationem eveniet impedit illo?</p>

                <h3 id="droitsAmendement" className='bold titre_h3'> Droit d’amendement</h3>

                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Et, deleniti deserunt! Nemo tempore ducimus quos ut modi fugit at! Minima earum molestiae quasi? Iusto fugiat suscipit velit quam amet laborum adipisci necessitatibus explicabo doloribus odio praesentium molestias, quisquam nostrum culpa, odit facere deserunt labore ea esse nemo delectus? Reiciendis alias hic placeat iure tempore aliquam. Sit perferendis consequatur voluptates omnis fugiat. Iusto porro quibusdam veniam adipisci laboriosam, minus debitis a nobis voluptatum. Tenetur, molestias rem! Ipsam corporis corrupti sapiente alias voluptatibus? Voluptate itaque ex fuga? Fugit, sunt eligendi! Aspernatur earum quae molestiae asperiores tempora quo fuga, amet voluptatem expedita adipisci.</p>

                <h3 id="droitsStatutaire" className='bold titre_h3'>Vos droits statutaires concernant vos données</h3>

                    <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Praesentium laborum corrupti dolor perferendis natus consequuntur illo eius dignissimos? Qui tempora laudantium dolorum exercitationem delectus incidunt consequatur quod. A consectetur hic impedit ducimus distinctio autem omnis mollitia iure ipsam, cupiditate perspiciatis labore blanditiis, fuga cumque rem odit repellat quisquam incidunt! Laborum.</p>
                    <p>Cum quisquam maiores iure blanditiis praesentium quae, vitae nisi perferendis error ullam est, harum possimus, voluptatibus nostrum quidem dolores illum. Ad quae, exercitationem quo reiciendis rem tenetur quibusdam voluptatem, veritatis culpa numquam esse corporis? Consequuntur labore temporibus, eos, porro ullam, in architecto error voluptatem cupiditate vel saepe deserunt nisi exercitationem.</p>
                    <p>Blanditiis, velit omnis recusandae est aperiam excepturi magnam deserunt magni eum unde molestiae amet aliquam dolore qui ipsa! Earum placeat inventore pariatur recusandae, quae doloremque esse, nam sunt iste sed non necessitatibus. Consectetur dicta sed tenetur quidem, rem, ducimus dignissimos culpa assumenda voluptatibus cumque doloremque impedit ipsa dolor debitis! Distinctio.</p>
                    <p>Illo fugiat impedit, commodi doloribus nihil accusantium, eius id quasi et distinctio incidunt nesciunt? Quasi molestias aut voluptate? Doloribus amet quaerat, ipsa, dolores laboriosam officia numquam hic aspernatur esse laudantium, ad inventore nihil nisi culpa repellendus soluta sint facere adipisci! Laboriosam cum placeat debitis unde harum aut repudiandae deserunt iusto?</p>
                    <p>Dolores voluptas laboriosam deserunt repellat nobis unde ratione assumenda earum. Illo velit, laboriosam illum modi tempora sit? Adipisci officia ab, optio, blanditiis sed unde non sunt animi, nostrum et quia aliquid placeat deserunt! Reiciendis eveniet eos voluptates provident temporibus earum odio aperiam vitae voluptas ipsam repellat, impedit, ab nisi quae.</p>

                <h3 id="coordonnees" className='bold titre_h3'>Nos coordonnées</h3>
                
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Corporis magnam quas quod velit? Assumenda consequuntur officia perferendis. Autem facere aspernatur necessitatibus vitae est nisi quae mollitia ex totam, error molestiae nihil amet quibusdam voluptas! Consectetur saepe facilis exercitationem assumenda ducimus temporibus, incidunt explicabo eos maxime aperiam, veritatis id voluptatem veniam.</p>

                    <div id='coords'>
                        <p>Responsable de la protection des données Vinted:</p>
                        <p>Book2life</p>
                        <p>26 Rue de la gloire</p>
                        <p>81800 Rabastens</p>
                        <p>France</p>
                    </div>
            </section>
        </main>     
    )
}

export default PrivacyPolicy