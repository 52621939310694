/*export const config = {
    api_url: "http://localhost:4000",
    pict_url: "http://localhost:4000/images/"
};*/

/*export const config = {
    api_url: "http://manuellamaya.sites.3wa.io:3000",
    pict_url: "http://manuellamaya.sites.3wa.io:3000/images/"
};*/

export const config = {
    api_url: "https://book2lifeapi.book2life.fr",
    pict_url: "https://book2lifeapi.book2life.fr/images/"
};


