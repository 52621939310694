import React from 'react'


//Page termes et conditions d'utilisation
const TermesAndConditions = (props)=>{

    return (
        <main id="TermesAndConditions" className='footerLink'>
            <h1 className='titre_h1'>Termes et Conditions d'utilisation</h1>

            <section>
                <h3 className='uppercase bold titre_h3'>Lorem.</h3>
                    <div>
                        <h4 className='bold titre_h4'>Lorem, ipsum dolor.</h4>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Placeat quam nisi, ab et quos laudantium nam voluptatem distinctio a tempore molestias corrupti assumenda? Maiores at accusantium itaque ullam repellendus? Fuga aperiam, fugiat ullam earum perspiciatis quaerat praesentium quia. Magnam omnis est quas, repellat itaque perspiciatis architecto iure officia, dolores facilis aperiam nulla possimus quidem! Rerum soluta nobis quo minima, ducimus necessitatibus a sapiente nisi rem quasi maxime, facilis sint hic perspiciatis. Delectus, et ea est exercitationem cum deserunt error assumenda hic praesentium aut quisquam voluptatum omnis id voluptate numquam dolores accusamus labore quas illum facere. Commodi veritatis id ex minus?</p>

                        <h4 className='bold titre_h4'>Illo, porro officia!</h4>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Placeat quam nisi, ab et quos laudantium nam voluptatem distinctio a tempore molestias corrupti assumenda? Maiores at accusantium itaque ullam repellendus? Fuga aperiam, fugiat ullam earum perspiciatis quaerat praesentium quia. Magnam omnis est quas, repellat itaque perspiciatis architecto iure officia, dolores facilis aperiam nulla possimus quidem! Rerum soluta nobis quo minima, ducimus necessitatibus a sapiente nisi rem quasi maxime, facilis sint hic perspiciatis. Delectus, et ea est exercitationem cum deserunt error assumenda hic praesentium aut quisquam voluptatum omnis id voluptate numquam dolores accusamus labore quas illum facere. Commodi veritatis id ex minus?</p>

                        <h4 className='bold titre_h4'>Dicta, accusamus dignissimos.</h4>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Placeat quam nisi, ab et quos laudantium nam voluptatem distinctio a tempore molestias corrupti assumenda? Maiores at accusantium itaque ullam repellendus? Fuga aperiam, fugiat ullam earum perspiciatis quaerat praesentium quia. Magnam omnis est quas, repellat itaque perspiciatis architecto iure officia, dolores facilis aperiam nulla possimus quidem! Rerum soluta nobis quo minima, ducimus necessitatibus a sapiente nisi rem quasi maxime, facilis sint hic perspiciatis. Delectus, et ea est exercitationem cum deserunt error assumenda hic praesentium aut quisquam voluptatum omnis id voluptate numquam dolores accusamus labore quas illum facere. Commodi veritatis id ex minus?</p>

                        <h4 className='bold titre_h4'>Sequi, libero dolore.</h4>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Placeat quam nisi, ab et quos laudantium nam voluptatem distinctio a tempore molestias corrupti assumenda? Maiores at accusantium itaque ullam repellendus? Fuga aperiam, fugiat ullam earum perspiciatis quaerat praesentium quia. Magnam omnis est quas, repellat itaque perspiciatis architecto iure officia, dolores facilis aperiam nulla possimus quidem! Rerum soluta nobis quo minima, ducimus necessitatibus a sapiente nisi rem quasi maxime, facilis sint hic perspiciatis. Delectus, et ea est exercitationem cum deserunt error assumenda hic praesentium aut quisquam voluptatum omnis id voluptate numquam dolores accusamus labore quas illum facere. Commodi veritatis id ex minus?</p>
                    </div>
        

                <h3 className='uppercase bold titre_h3'>Quasi?</h3>

                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Eius quibusdam, harum pariatur, cum alias quia iure minima porro, nostrum accusantium atque sunt! Saepe, exercitationem iure! Laudantium minima et mollitia excepturi exercitationem rem iure, adipisci nulla aperiam facere! Non ratione necessitatibus sunt magnam debitis rerum aspernatur iste! Laudantium sequi reprehenderit alias distinctio eligendi animi delectus numquam voluptatibus doloribus optio quos harum magni omnis, expedita necessitatibus obcaecati, molestiae debitis ut. Illum harum dolorem optio provident voluptates tempora illo fugit. Culpa numquam error iure, aspernatur rem modi molestias minus voluptatum, dicta quam exercitationem? Provident, dolores. Vel atque necessitatibus magni blanditiis nam reprehenderit explicabo odio repudiandae voluptatibus, fuga vero minus ipsam animi natus iusto totam quod, aliquam porro harum aut. Magni iure odit eius, repellendus pariatur perspiciatis voluptatibus deserunt possimus ad commodi dolore praesentium aperiam cum architecto nisi qui, ipsa ex necessitatibus sed aspernatur blanditiis nesciunt laborum. Libero, aliquam voluptatum sunt pariatur adipisci alias?</p>
                <p>Atque error possimus maiores officiis fugit consequatur numquam velit. Veritatis, molestias totam fuga labore magni nam praesentium excepturi veniam temporibus aliquam, necessitatibus enim eius placeat aperiam esse quidem ratione, voluptatum perferendis nostrum quibusdam accusamus libero nesciunt officia. Deserunt modi temporibus incidunt accusamus, assumenda praesentium ea pariatur odit? Id est facere quisquam iure doloribus ratione quas eos esse fuga beatae at facilis quae earum sed totam vitae temporibus, dolorum necessitatibus pariatur saepe! Rem eligendi eius odio culpa natus veniam. Voluptatum, velit ad officia ducimus placeat a perspiciatis ratione odio, sunt accusamus quo aperiam temporibus eligendi quasi similique ut quidem iste harum numquam et? Minus, veniam dolorum! Accusantium et voluptatum dignissimos ab voluptas quidem distinctio in quia, consequuntur voluptate earum dolorum illo ullam nisi quo enim quod non, architecto excepturi commodi facilis facere sit illum numquam? Cum sed placeat harum aliquam officia, aspernatur tempore rerum voluptatibus quisquam atque dolor nemo eum aut!</p>
                <p>Dolorem quibusdam facere ullam magnam sint perspiciatis quae reiciendis voluptate quisquam qui ratione esse nobis, ab, libero molestias rem tempore quasi ipsum, mollitia deleniti! Nihil sint soluta officiis voluptatem quia aliquam nemo illum repellat blanditiis ipsa. Voluptas temporibus maxime doloremque at quos aperiam distinctio, error eligendi similique exercitationem excepturi dolore sequi repudiandae rem velit enim et, ipsam mollitia blanditiis consequatur rerum eum? Repellat unde quidem laborum quia, veritatis natus omnis facilis doloribus incidunt tenetur voluptatum assumenda asperiores. Repellendus iure, molestiae magnam vel unde sapiente distinctio consequatur sequi cupiditate optio! Dolore alias exercitationem voluptatum veniam laboriosam beatae ad veritatis harum aperiam earum molestiae, nesciunt asperiores ut optio totam odio et similique dicta, itaque neque cupiditate! Facilis harum dignissimos reiciendis, cumque odio quas consequatur, veniam consequuntur repellat assumenda velit tempore deleniti. Suscipit, velit tempore fuga eos rem rerum earum nesciunt maxime nam quos in impedit dolores non voluptates reiciendis numquam optio ea!</p>

                <h3 className='uppercase bold titre_h3'>Totam!</h3>
                    <div>
                        <h4 className='bold titre_h4'>Lorem, ipsum dolor.</h4>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Placeat quam nisi, ab et quos laudantium nam voluptatem distinctio a tempore molestias corrupti assumenda? Maiores at accusantium itaque ullam repellendus? Fuga aperiam, fugiat ullam earum perspiciatis quaerat praesentium quia. Magnam omnis est quas, repellat itaque perspiciatis architecto iure officia, dolores facilis aperiam nulla possimus quidem! Rerum soluta nobis quo minima, ducimus necessitatibus a sapiente nisi rem quasi maxime, facilis sint hic perspiciatis. Delectus, et ea est exercitationem cum deserunt error assumenda hic praesentium aut quisquam voluptatum omnis id voluptate numquam dolores accusamus labore quas illum facere. Commodi veritatis id ex minus?</p>

                        <h4 className='bold titre_h4'>Illo, porro officia!</h4>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Placeat quam nisi, ab et quos laudantium nam voluptatem distinctio a tempore molestias corrupti assumenda? Maiores at accusantium itaque ullam repellendus? Fuga aperiam, fugiat ullam earum perspiciatis quaerat praesentium quia. Magnam omnis est quas, repellat itaque perspiciatis architecto iure officia, dolores facilis aperiam nulla possimus quidem! Rerum soluta nobis quo minima, ducimus necessitatibus a sapiente nisi rem quasi maxime, facilis sint hic perspiciatis. Delectus, et ea est exercitationem cum deserunt error assumenda hic praesentium aut quisquam voluptatum omnis id voluptate numquam dolores accusamus labore quas illum facere. Commodi veritatis id ex minus?</p>

                        <h4 className='bold titre_h4'>Dicta, accusamus dignissimos.</h4>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Placeat quam nisi, ab et quos laudantium nam voluptatem distinctio a tempore molestias corrupti assumenda? Maiores at accusantium itaque ullam repellendus? Fuga aperiam, fugiat ullam earum perspiciatis quaerat praesentium quia. Magnam omnis est quas, repellat itaque perspiciatis architecto iure officia, dolores facilis aperiam nulla possimus quidem! Rerum soluta nobis quo minima, ducimus necessitatibus a sapiente nisi rem quasi maxime, facilis sint hic perspiciatis. Delectus, et ea est exercitationem cum deserunt error assumenda hic praesentium aut quisquam voluptatum omnis id voluptate numquam dolores accusamus labore quas illum facere. Commodi veritatis id ex minus?</p>

                        <h4 className='bold titre_h4'>Sequi, libero dolore.</h4>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Placeat quam nisi, ab et quos laudantium nam voluptatem distinctio a tempore molestias corrupti assumenda? Maiores at accusantium itaque ullam repellendus? Fuga aperiam, fugiat ullam earum perspiciatis quaerat praesentium quia. Magnam omnis est quas, repellat itaque perspiciatis architecto iure officia, dolores facilis aperiam nulla possimus quidem! Rerum soluta nobis quo minima, ducimus necessitatibus a sapiente nisi rem quasi maxime, facilis sint hic perspiciatis. Delectus, et ea est exercitationem cum deserunt error assumenda hic praesentium aut quisquam voluptatum omnis id voluptate numquam dolores accusamus labore quas illum facere. Commodi veritatis id ex minus?</p>
                    </div>
              

                <h3 className='uppercase bold titre_h3'>Quod!</h3>

                    <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Magnam adipisci temporibus recusandae pariatur veritatis, eaque ab nobis, quas consequatur, ea consequuntur expedita in sapiente. Quibusdam labore ipsam deserunt nam placeat quas possimus fugiat nobis odit nisi? Iusto nemo voluptate eaque debitis eum quia ducimus repudiandae, quas facilis! Officia eos perspiciatis qui quidem cum facilis repellat iusto totam. Velit voluptatibus esse odio labore delectus, dicta consequatur doloremque? Blanditiis fugiat vero numquam voluptas alias exercitationem corrupti ipsa iste beatae, laboriosam modi, consequuntur amet. Libero optio praesentium ipsam iure doloribus non quo excepturi aperiam corrupti? Nobis, maxime nisi tempore consequuntur fuga quibusdam cum.</p>
                    <p>Sunt earum possimus incidunt suscipit ea pariatur, sit, consequuntur inventore est id saepe debitis dolores modi, porro eligendi! Totam unde sit adipisci debitis maiores fugiat et maxime iure nam rem, quisquam dolor magnam consectetur soluta quas beatae velit nulla voluptates quibusdam enim! Libero numquam reiciendis eius voluptates tempore vitae quia facilis laboriosam, alias accusamus repellat, sint ab cumque, aliquid minima odit obcaecati quod? Aliquid, qui minima quisquam animi vitae quas sapiente exercitationem aliquam iste, eligendi vero, ad facere. Voluptatum eius placeat doloribus assumenda officiis libero ullam quibusdam ratione, quo laudantium et aliquam dignissimos adipisci velit culpa, blanditiis fugit, alias perspiciatis.</p>
                    <p>Atque necessitatibus quas perferendis voluptatum molestias consequatur. Quos maiores reprehenderit corrupti, ea blanditiis libero ipsum dolor maxime ullam veritatis debitis deserunt voluptas laudantium necessitatibus quasi incidunt unde repellendus laboriosam, dolorem aliquid aperiam molestiae qui. Facere atque, animi minus voluptas in recusandae assumenda culpa itaque eum ea explicabo officia consequatur error tenetur ducimus commodi cupiditate placeat. Assumenda perferendis incidunt sapiente dolore saepe nisi maiores enim, similique blanditiis aperiam deserunt deleniti voluptates labore magni hic voluptatibus impedit vero facilis. Ab iure ducimus error quasi commodi consequatur, doloremque, adipisci rem obcaecati a hic aliquam laudantium dolorem. Blanditiis cupiditate ab consequatur hic, sequi perferendis.</p>
                    <p>Id voluptatibus dolorum neque consequuntur! Ducimus nisi, tenetur laboriosam totam eos blanditiis reiciendis ex eligendi ipsum deleniti at, atque aliquid doloribus! Corporis perferendis rerum voluptate animi iste placeat necessitatibus et. Quos vel nobis corrupti aut dolore accusamus eveniet cupiditate, nam voluptates incidunt fuga id iusto voluptate pariatur fugit. Consectetur dolorem perferendis ab animi doloremque in distinctio vero provident nobis voluptas sit atque, laudantium, fugit possimus temporibus nemo debitis illum, excepturi similique officia sed aliquam delectus? Delectus minus distinctio laboriosam aperiam, esse et nam, fugit minima nesciunt ex recusandae soluta iure numquam rerum doloremque? Voluptates iusto nisi minima explicabo veritatis repudiandae!</p>
                    <p>Ex harum delectus eveniet incidunt, nisi mollitia minima hic. Incidunt reiciendis eaque fugiat itaque quaerat iure rem dolore labore deleniti facere. Quisquam iure modi rerum, quidem dolore inventore suscipit aliquid quasi quibusdam officiis autem quo mollitia est numquam quod vero pariatur? Repellendus pariatur ut consequatur libero unde? Quo reprehenderit quibusdam voluptatibus ut sunt natus eligendi, ipsa tempore voluptatum impedit ullam sequi eaque, porro modi consequuntur accusamus molestias ratione excepturi. Dolorem, id? Nulla, reprehenderit saepe eos veritatis maxime earum atque tenetur labore voluptas nemo rerum quam esse perspiciatis ab libero assumenda illum expedita aliquid voluptatum deleniti numquam aliquam explicabo! Veritatis, quia.</p>
                
            </section>
        </main>     
    )
}

export default TermesAndConditions